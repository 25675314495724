<template>
    <div class="path" :class="{ reverse: role == 5 }">
        <div class="item" v-for="(item, index) in before" :key="index">
            <span class="text" @click="back">{{ item }}</span>
            <span class="modify el-icon-arrow-right"></span>
        </div>
        <div class="item current">{{ current }}</div>
    </div>
</template>

<script>
export default {
    name: "HeadPath",
    data() {
        return {
            role: localStorage.getItem("role") || ""
        };
    },
    props: {
        list: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        before() {
            return this.list.slice(0, this.list.length - 1);
        },
        current() {
            return this.list.slice(-1)[0];
        },
    },
    components: {},
    methods: {
        back() {
            this.$router.back();
        },
    },
};
</script>

<style scoped lang="scss">
.path {
    display: flex;
    margin-bottom: 40px;
    color: #a8a8b8;
    .item {
        display: flex;
        justify-content: center;
        align-items: center;
        .text {
            transition: all 0.3s;
            cursor: pointer;
        }
        .text:hover {
            color: #2821fc;
        }
        .modify {
            margin: 0 5px;
            /* margin-top: 1px; */
        }
    }

    .current {
        color: #332d42;
    }
}
.reverse {
    color: #332d42 !important;
    .current {
        color: #a8a8b8 !important;
    }
}
</style>
